import React from 'react'

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

const BasicAutoComplete = ({
  options = [
    //{ title: 'The Shawshank Redemption', year: 1994 },
    //{ title: 'The Godfather', year: 1972 },
    //{ title: 'The Godfather: Part II', year: 1974 },
  ],
  label = "Search Events",
  onTextChange = () => {},
  size = "small",
  width = '190px',
  ...props
}) => {

  // Should have thse options
  // const [value, setValue] = React.useState(options[0].title);
  // const [inputValue, setInputValue] = React.useState('');

  const handleChange = (event, value) => {
    onTextChange(event, value)
  }

  const handleSelect = (event, value) => {
    console.log('DEBUG: handleSelect ', event, value);
    onChange(event, value)
  }

  return (
    <Autocomplete
      id="event-search"
      freeSolo
      options={options.map(option => option.title)}
      onChange={handleChange}
      onInputChange={(e, v) => handleChange(e, v)}
      renderInput={params => {
        return (
          <TextField
            {...params}
            label={label}
            size={size}
            sx={{ fontSize: '0.8rem' }}
            InputProps={{ sx: { borderRadius: 20, fontSize: '0.8rem' } }}
            type="search"
            />
        )
      }}
      size={size}
      width={width}
      style={{
        borderRadius: '10rm',
        fontSize: '1.4rem'
      }}
      sx={{ width: width }}
      />
  )

}

export default BasicAutoComplete
