import React from 'react';

import axios from 'axios';
import { useState, useEffect } from 'react';
import { renderToString } from 'react-dom/server';

export const Email = ({ message }) => (
    <div>
        <h1>Error Notification</h1>
        <p>{ message }</p>
    </div>
)

export const sendEmail = async (error, data) => {

    try {
        const endpoint = "https://api.vibemap.com/v0.3/send-email/"
        const formattedData = data ? JSON.stringify(data, null, 2) : ''; // Indent JSON with 2 spaces

        const emailHtml = `<div>An error occurred: ${error}</div> <br/><br/> <pre>${formattedData}</pre>`

        const postData = {
            email: data?.email || 'steve@vibemap.com',
            subject: data?.subject || 'Hello',
            message: emailHtml || 'Hello there',
        };

        const response = await axios.post(endpoint, postData);

        if (response.status === 200) {
            console.log('Email sent successfully');
        } else {
            console.error('Failed to send email:', response.data);
        }

    } catch (err) {
        console.error('Failed to send error notification email:', err);
    }
};

const useErrorNotify = (error, data) => {
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        if (error) {
            debugger;
            setHasError(true);
            sendEmail(error);
        }
    }, [error]);


    return hasError;
};

export default useErrorNotify;